/* Adapted from https://codepen.io/sungaila/pen/LzMgjE */

.propeller-container {
	perspective: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
  z-index: 10;
}

.propeller-square {
	width: 42px;
	height: 42px;
	transform: rotateY(45deg);
	animation: rotateAnimation 5s linear infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(0deg);}
	to {transform: rotateY(359deg);}
}
